<template>
  <div>
    <v-row>
      <v-col
        md="8"
        sm="12"
      >
        <v-card elevation="4">
          <v-card-title
            class="d-flex justify-space-between"
            primary-title
          >
            <h4>
              Nouvelle Procuration
            </h4>
            <v-icon
              top
              right
            >
              {{ icons.mdiBriefcase }}
            </v-icon>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <h4>Mandant</h4>
          </v-card-text>
          <v-card-text>
            <v-form>
              <v-row>
                <v-btn-toggle
                  v-model="mandant.gender"
                  tile
                  color="primary"
                  group
                >
                  <v-btn value="male">
                    Homme
                  </v-btn>

                  <v-btn value="female">
                    Femme
                  </v-btn>
                </v-btn-toggle>
              </v-row>
              <v-row>
                <v-col
                  md="4"
                  sm="12"
                >
                  <v-text-field
                    v-model="mandant.nom"
                    label="Nom"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  md="4"
                  sm="12"
                >
                  <v-text-field
                    v-model="mandant.prenom"
                    label="Prénom"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  md="4"
                  sm="12"
                >
                  <v-text-field
                    v-model="mandant.birthday"
                    type="date"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  md="12"
                  sm="12"
                >
                  <v-text-field
                    v-model="mandant.address1"
                    label="Adresse ligne 1"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  md="12"
                  sm="12"
                >
                  <v-text-field
                    v-model="mandant.address2"
                    label="Adresse ligne 2"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  md="6"
                  sm="6"
                >
                  <v-text-field
                    v-model="mandant.postcode"
                    label="Code postal"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  md="6"
                  sm="6"
                >
                  <v-text-field
                    v-model="mandant.city"
                    label="Ville"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <h4>Mandataire</h4>
          </v-card-text>
          <v-card-text>
            <v-form>
              <v-row>
                <v-btn-toggle
                  v-model="mandataire.gender"
                  tile
                  color="primary"
                  group
                >
                  <v-btn value="male">
                    Homme
                  </v-btn>

                  <v-btn value="female">
                    Femme
                  </v-btn>
                </v-btn-toggle>
              </v-row>
              <v-row>
                <v-col
                  md="4"
                  sm="12"
                >
                  <v-text-field
                    v-model="mandataire.nom"
                    label="Nom"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  md="4"
                  sm="12"
                >
                  <v-text-field
                    v-model="mandataire.prenom"
                    label="Prénom"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  md="4"
                  sm="12"
                >
                  <v-text-field
                    v-model="mandataire.birthday"
                    type="date"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  md="6"
                  sm="6"
                >
                  <v-text-field
                    v-model="mandataire.birthPostcode"
                    label="Code postal de naissance"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  md="6"
                  sm="6"
                >
                  <v-text-field
                    v-model="mandataire.city"
                    label="Ville de naissance"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  md="12"
                  sm="12"
                >
                  <v-text-field
                    v-model="mandataire.address1"
                    label="Adresse ligne 1"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  md="12"
                  sm="12"
                >
                  <v-text-field
                    v-model="mandataire.address2"
                    label="Adresse ligne 2"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  md="6"
                  sm="6"
                >
                  <v-text-field
                    v-model="mandataire.postcode"
                    label="Code postal"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  md="6"
                  sm="6"
                >
                  <v-text-field
                    v-model="mandataire.city"
                    label="Ville"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        md="4"
        sm="12"
      >
        <v-card elevation="4">
          <v-card-text>
            <v-select
              placeholder="Choisir un mandataire"
              :items="mandantaires"
              :item-text="(val) => `${val.prenom} ${val.nom}`"
              :item-value="(val) => val"
              @change="updateMandataire"
            ></v-select>
          </v-card-text>
          <v-card-text>
            <v-text-field
              v-model="mandataire.place"
              label="Fait à"
            >
            </v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="success"
              block
              @click="generateProcuration"
            >
              GO
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiBriefcase } from '@mdi/js'

export default {
  metaInfo: {
    title: 'Nouvelle Procuration',
    titleTemplate: '%s - Jimbo',
  },
  data: () => ({
    menuValue: false,
    mandantaires: [{
      place: 'Cannes',
      nom: 'Parienti',
      prenom: 'Sarah',
      birthPostcode: '75012',
      birthCity: 'Paris',
      birthday: '27-06-1995',
      address1: '4 Avenue Saint-Jean',
      gender: 'female',
      address2: '',
      city: 'Cannes',
      postcode: '06400',
    },
    {
      place: 'Paris',
      nom: 'Nacache',
      prenom: 'Esther',
      birthPostcode: '75012',
      birthCity: 'Paris',
      birthday: '1995-06-27',
      address1: '29 Rue Médéric',
      gender: 'female',
      address2: '',
      city: 'Paris',
      postcode: '75017',
    },
    {
      place: 'Paris',
      nom: 'Parienti',
      prenom: 'Anaële',
      birthPostcode: '75012',
      birthCity: 'Paris',
      birthday: '1992-08-12',
      address1: '101 Avenue maréchal Juin',
      gender: 'female',
      address2: '',
      city: 'Cannes',
      postcode: '06400',
    },
    {
      place: 'Paris',
      nom: 'Parienti',
      prenom: 'Shirley',
      birthPostcode: '75014',
      birthCity: 'Paris',
      birthday: '1985-11-29',
      address1: '18 Rue Saint-Ferdinand',
      gender: 'female',
      address2: '',
      city: 'Paris',
      postcode: '75017',
    },
    ],
    mandant: {
      nom: 'Utilisatrice',
      gender: 'female',
      prenom: 'De Test',
      address1: '29 Rue de la sottise',
      address2: '',
      postcode: '75012',
      city: 'Paris',
      birthday: '1992-12-12',
    },
    mandataire: {
      place: 'Paris',
      nom: '',
      prenom: '',
      birthPostcode: '',
      birthCity: '',
      address1: '29 Rue de la sottise',
      address2: '',
      postcode: '75012',
      city: 'Paris',
      birthday: '',
    },
    icons: {
      mdiBriefcase,
    },
  }),
  methods: {
    generateProcuration() {
      const proc = {
        mandant: this.mandant,
        mandataire: this.mandataire,
      }
      this.$http.post(`${process.env.VUE_APP_API_URL}/procurations/create-pdf`, proc)
        .then(res => {
          console.log(res)
          this.$store.commit('snackMe', {
            color: 'success',
            text: 'Procuration créer avec succès',
            value: true,
          })
          window.open(`${process.env.VUE_APP_API_URL + res.data.pdf.url}`)
        })
        .catch(err => console.log(err))
        .finally()
    },
    updateMandataire(val) {
      console.log(val)
      this.mandataire = val
    },
  },

}
</script>

<style>

</style>
